#services{
    height: auto !important;
    max-width: 100vw;
}

.mainServiceContainer{
    /* background-color: aliceblue; */
    width: 79.5vw;
    /* height: 80vh; */
    margin-left: 24vmin;
    /* margin-right: 30rem; */
    margin-top: 8vmin;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
position: relative;
    transition: .5s all linear;
    gap: 80px;
}
.serviceCard{
    padding: 1vmin 2vmin;
    width: 30rem;
    height: 20rem;
    transition: .3s all linear;
    /* margin: 4.4rem 1rem; */
    margin: 1vmin .2vmin;
    /* border-radius: 1rem; */
    line-height: 4.5vmin;
}
.sCardIcon{
    margin-bottom: 1.8vmin ;
}
.sCardIcon > img{
    vertical-align: middle;
    border-style: none;
}
.sCardHeading{
    font-size: 3.4vmin;
    font-weight: 700;
    margin-bottom: 1vmin;
    /* line-height: 1.2rem; */
    color: white;
}
.sCardDesc{
    color: white;
    font-size: 2.5vmin;
    margin-bottom: 2vmin;
    font-weight: 200;
    word-wrap: break-word;
}

/* Animation */

@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: 2s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}