#contact{
    height: 115vh;
    max-width: 100vw;
    /* position: relative;
    z-index: 1;
    margin-bottom: 20rem; */
}
.contactContainer{
    margin: 0rem 15vmin;
    justify-content: space-between;
    /* border: 1rem solid pink; */
    height: auto;
    flex-wrap: wrap;
}
.contactForm{
    width: 50%;
    margin-left: 10vmin;
    margin-top: 6vmin;
    /* border-top: 1px solid #222;
    border-bottom: 1px solid #222; */
    /* background-color: aqua; */
    /* border: 1px solid green; */
    
}


 .inputBox{
    position: relative;
}

 .inputBox input,textarea{
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    background: transparent;
    outline: none;
    resize: none;
}

 .inputBox label{
    position: absolute;
    top: 0;
    left: 0;
    letter-spacing: 1px;
    padding: 10px 0;
    font-size: 2.4vmin;
    color: rgba(251, 243, 243, 0.939);
    transition: 0.5s;
    text-transform: capitalize;
    z-index: -1;
    font-weight: 100;
}   

 .inputBox input:focus ~ label, .inputBox input:valid ~ label{
    top: -20px;
    left:0;
    color: var(--primary_color);
    font-size: 12px;
    font-weight:bold
}
 .inputBox textarea:focus ~ label, .inputBox textarea:valid ~ label{
    top: -20px;
    left:0;
    color: var(--primary_color);
    font-size: 12px;
    font-weight:bold
}
.contactForm > input{
    color: #fff;
  border: 2px solid rgba(255,255,255,.5);
  transition: .3s all ease;
  font-size: 11px;
  border-radius: 30px;
  padding: 2.5vmin 5vmin;
  letter-spacing: .1rem;
  text-transform: uppercase;
  font-weight: 900;
      text-decoration: none;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    line-height: 2vmin;
    margin-top: 3vmin;
    cursor: pointer;
}
.contactForm > input:hover{
  border: 2px solid rgba(255,255,255,.8);
}

.contactInfo{
    /* max-width: 33%; */
    width: 30%;
    /* padding: 0 3rem; */
    transform: translate(-2vmin,-3vmin);
    /* border: 1px solid blue; */
}
.cInfo{
    margin-bottom: 8vmin;
    /* border: 1px solid firebrick; */
}
.cInfo >span{
    font-size: 11px;
    letter-spacing: .1rem;
    font-weight: 400;
    text-transform: uppercase;
    color: rgba(255,255,255,.5);
}
.cInfo > p{
    margin-top: .5rem;
    font-size: 20px;
    color: #fff;
    font-weight: 100;
}

@media only screen and (max-width:1024px) {
    .contactContainer{
        margin: 0;
    }
    .inputBox label {
        font-size: 2vmin;
    }
    .contactForm > input {
        padding: 2vmin 4vmin;
    }
    .contactInfo{
        transform: translate(0vmin,2vmin);
    }
}

@media only screen and (max-width:767px){
    /* #contact{
        height: auto;
    } */
    .contactContainer{
        justify-content: center;
    }
    .contactForm{
        width: 100%;
        margin: 0 6vmin;
    }
    .inputBox label {
        font-size: 2.8vmin;
    }
    .contactInfo{
        width: auto;
        margin-top: 6vmin;
    }
    .cInfo{
        text-align: center;
    }
    .contactForm > input {
        padding: 4.5vmin 4vmin;
        font-size: 2.4vmin;
    }
}