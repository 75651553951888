#skills{
    max-width: 100vw;
    height: 50vh;
    /* background-color: aliceblue; */
}
.skillContainer{
    width: 79vw;
    /* height: 50vh; */
    /* background-color: aliceblue; */
    flex-wrap: wrap;
    margin-left: 20vmin;
    padding: 6vmin 2vmin;
    justify-content: space-between;
}

.skillCard{
    width: 31vmin;
    height: 15vmin;
    /* background-color: black;
    border: 1px solid red; */
    flex-direction: column;
}
.number{
    font-size: 69px;
    font-weight: 700;
    font-family: "Arimo",sans-serif;
    color: #d63447;
}
.percent{
    position: relative;
    top: -30px;
    font-size: 28px;
    font-weight: 400;
    color: white;
}
.skillTopic{
    text-transform: uppercase;
    letter-spacing: .1rem;
    font-size: 11px;
    font-weight: 400;
    color: white;
}