#portfolio{
    height: 100vh;
    max-width: 100vw;
    /* background-color: #000; */
    overflow: hidden;
    display: flex;
    /* border-top: solid 2px white; */
    position: relative;
	padding-top:2.5vmin
    /* padding-bottom: 5rem; */
}
/* #portfolio::after{
  content: "";
  position: absolute;
  width: 50%;
  left: 34rem;
  bottom: 0%;
  border: 0.1px solid rgba(255, 255, 255, 0.064);
} */

#portfolio >div{
    margin-top: 6vmin;
    /* border-right: 0.1px solid rgba(255,255,255,0.2); */
    /* border-top: 0.1px solid rgba(255,255,255,0.2); */
}
/* .portContainer1{
    margin-left: 4rem;
    background-color: aqua;
    display:grid;
    grid-template-columns: .8fr 1.2fr;
} */
.portHead{
    /* background-color: blue; */
    margin-left: 1vmin;
    border: none !important;
}
.portHead h1{
    font-size: 5vmin;
    color: white;
    font-weight: 400;
}
.portHeadAni{
    transform: scale(0.94);
}

.portList{
    flex-direction: column;
    /* background-color: blueviolet; */
    align-items: flex-start !important;
    /* width: 100%; */
    padding: 0 10px;
}
.menu_btn{
    font-size: 2.4vmin;
    color: white;
    font-weight: 100;
    text-transform: uppercase;
    background-color: transparent;
    cursor: pointer;
    margin-top: 1.8vmin;
    transition: all .2s linear;
    border: none;
}

.menu_btn:hover{
    color: var(--primary_color);
    font-weight: 500;
}
.menu_active{
    color: var(--primary_color);
    font-weight: bold;
}

.projectContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* height: 70vh;*/
    width: 82.15vw;
    /* background-color: aqua; */
    flex-direction: row;
    /* border: 2px solid white; */
    overflow-y: scroll;
    position: relative;
    transition: .5s all linear;
    scrollbar-color:  rgba(255,255,255,0.2) rgba(0,0,0);
  scrollbar-width: thin;
}
/* .projectContainer::after{
    content: "";
    position: absolute;
    width: 55%;
    left: 21.2rem;
    border: 0.1px solid rgba(255, 255, 255, 0.064);
} */


/* Scroll bar */
.projectContainer::-webkit-scrollbar {
    width: .5em ;
  }
   
  .projectContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
  }
   
  .projectContainer::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0);
    outline: 1px solid rgba(255, 255, 255,0.2);
    border-radius: 1.5rem;
    transition: all .5s linear;
  }
  .projectContainer::-webkit-scrollbar-thumb:hover{
    background-color: rgba(255,255,255,0.2);
  }

  /* end of scroll bar */

.projectCard{
    padding: 0 20vmin;
    /* border-right: 0.1px solid rgba(255,255,255,0.2); */
    width: 35vmin;
    height: 35vmin;
    /* box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0.5); */
    overflow: hidden;
    position: relative;
    transition: .3s all linear;
    margin: 4.4vmin 4vmin;
    border-radius: 1rem;
}

/* card animation */

.cardOuterAni{
    width: 0%;
    height: 100% !important;
    background: var(--primary_color);
    position: absolute;
    animation: secBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
    animation-delay: .2s;
    display: flex;
    z-index: 10;
}

@keyframes secBlock {
    0% {
      width: 0%;
      left: 0;
  
    }
    50% {
      width: 100%;
      left: 0;
  
    }
    100% {
      width: 0;
      left: 100%;
    }
  }

  .cardAniInfo{
    animation: secFadeIn 2s forwards;
        animation-delay: 1s;
        opacity: 0 ;
        
  }

  @keyframes secFadeIn {
    0% {
      opacity: 0 ;
    }
    100% {
      opacity: 1;
    }
  }

  .cardAniInfo:hover{
    opacity: 1 !important;
  }
  
/* end of card animation */

.p_img{
    height: 100%;
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: .8s;
}
.projectCard:hover{
    /* opacity: .7; */
    box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0.786);
}

.p_title{
    position: absolute;
    /* display: inline; */
    font-size: 4vmin;
    top: 50%;
    left: 50%;
    text-transform: capitalize;
    /* background-color:#fff; */
    opacity: .8;
    color: white;
    border-radius: 1rem;
    width:100%;
    text-align: center;
    transform: translate(1500px,-50%);
    z-index: 10;
    transition: .8s;
    transition-timing-function: linear;
}


/* overlay of card */
.projectCard::after{
    content: '';
    width: 500px;
    height: 500px;
    position: absolute;
    top: 50%;
        left: 50%;
        transform: translate(-140%,-50%);
        z-index: 1;
        transition: .8s;
        background-color: rgba(13, 13, 13, 0.789);
        opacity: .8;
        border-radius: 50%;
    }
    
    .projectCard:hover::after{
        transform:translate(-50%,-50%);
    }

    .projectCard:hover .p_img{
        transform:translate(-50%,-50%) scale(1.4);
    }
    
    .projectCard:hover .p_title{
        transform: translate(-50%,-50%);
        transition-timing-function: ease;
    }
    