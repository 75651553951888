/* .article_card{
    width: 38% !important; 
    height: auto ;
    background:red;
    transition: .3s all linear !important;
} */
/* .article_card{
    width: 30% ;
    background-color: aquamarine;
    height: 100% !important;
}
.article_card:hover{
    
    box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0.786);
}
.article_img{
    width:100% !important;
    border-radius: 2vmin ;
} */

.blog_container{
    height: auto;
    width: 30% !important;
}

.card {
    position: relative;
    min-width: 20vmin;
    height: 45vmin;
    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
      inset -5px -5px 15px rgba(255, 255, 255, 0.1),
      5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    margin: 30px;
    transition: 0.5s;
  }
  
   .card:nth-child(1) .box .content a {
    background: var(--primary_color);
  }

   .card .box {
    position: absolute;
    /* top: 20px; */
    top: 2vmin;
    left: 2vmin;
    right: 2vmin;
    bottom: 2vmin;
    background: #2a2b2f;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: 0.5s;
    
  }
  
   .card .box:hover {
    transform: translateY(-50px);
  }
  
   .card .box:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.03);
    
  }
  
   .card .box .content {
    padding: 1.8vmin;
    text-align: center;
  }
  
   .card .box .content h2 {
    position: absolute;
    top: -.8vmin;
    right: 4vmin;
    font-size: 10vmin;
    color: rgba(255, 255, 255, 0.1);
  }
  
   .card .box .content h3 {
    font-size: 4.8vmin;
    color: #fff;
    z-index: 1;
    transition: 0.5s;
    margin-bottom: 2vmin;
  }
  
   .card .box .content p {
    font-size: 2.2vmin;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.9);
    z-index: 1;
    transition: 0.5s;
  }
  
   .card .box .content a {
    position: relative;
    display: inline-block;
    padding: 1.8vmin 3.8vmin;
    background: black;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    margin-top: 4vmin;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
  }
   .card .box .content a:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
    background: #fff;
    color: #000;
  }
  
  @media only screen and (min-width:768px)and (max-width:1024px){
    .blog_container{
        width:60% !important;
        
    }
  }

  @media only screen and (max-width:767px){
    .blog_container{
        width:80% !important;
        margin-right: 0px !important;
    }
  }