#home{
    height: 100vh;
    max-width: 100vw;
    background-color: #000;
    flex-direction: column;
    /* filter: grayscale(100%); */
    position: relative;
    /* background-image: url("../utility/images/home_1.png"); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: initial;
    display: grid;
    /* background-image: radial-gradient(
      circle farthest-side at var(--x, 100px) var(--y, 100px),
      #410d13dd 0%,
      transparent 100%
    ); */
}
#home::before{
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}
/* #home::after{
  content: "";
    position: absolute;
    width: 50%;
    left: 34rem;
    bottom: -5%;
    border: 0.1px solid rgba(255, 255, 255, 0.064);
} */


.homeHead{
    flex-direction: column;
    line-height: 7.5vmin;
    text-align: center;
    text-transform: uppercase;
    /* position: relative;
    height: 20vh;
    width: 20vw;
    background-color: aqua; */
    position: relative;
    /* width: 54vmax; */
    background-color: transparent;
    /* transform: translate(41.5rem,20rem); */
    color: white;
    align-self: center;
    justify-self: center;
}
.homeHead h1{
    font-size: 8vmin;
    font-weight: 100;
    letter-spacing: .1rem;
    line-height: 8vmin;
}
.homeHead >span{
    line-height: 14vmin;
    font-size: 10vmin;
    font-weight: 700;
}
.homeHead > strong, .homeHead > Typewriter{
    font-size: 3vmin;
    font-weight: 100;
}
.scrollBtn{
  position: relative;
  z-index: 10;
}
.scrollBtn a {
    /* padding-top: 60px;
    color: white;
    font-size: 1.2rem;
    text-decoration: none; */
    cursor: pointer;
  }

  .scrollBtn a span {
    position: absolute;
    top: 108%;
    left: 50%;
    width: 30px;
    height: 50px;
    margin-left: -15px;
    border: 2px solid #fff;
    border-radius: 50px;
    box-sizing: border-box;
  }
  .scrollBtn a span::before {
    position: absolute;
    top: 10px;
    left: 50%;
    content: '';
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sdb10 2s infinite;
    animation: sdb10 2s infinite;
    box-sizing: border-box;
  }
  @-webkit-keyframes sdb10 {
    0% {
      -webkit-transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      -webkit-transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes sdb10 {
    0% {
      transform: translate(0, 0);
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      transform: translate(0, 20px);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

