.lines-wrap {
    position: absolute;
    z-index: -1;
    left: 0;
    /* top: 0; */
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* background-color: aquamarine; */
    display: flex;
    justify-content: center;
  }
   .lines-inner {
    position: absolute;
    width: 80vw;
    /* left: 50%; */
    left: 11%;
    /* margin-left: -570px; */
    top: 0%;
    height: 100%;
    border-left: 1px solid #222;
    border-right: 1px solid #222;
    /* background-color: pink; */
  }
   .lines {
    position: absolute;
    /* left: 33.333333%;
    right: 33.333333%; */
    left: 33.333333%;
    right: 33.333333%;
    height: 100%;
    border-left: 1px solid #222;
    border-right: 1px solid #222;
  }

  @media only screen and (min-width:768px) and (max-width:1024px){
    .lines-inner{
      width: 202vw;
      left: 20%;
    }
    .lines{
      left: 32%;
    }
  }

  @media only screen and (max-width:767px){
    .lines-wrap{
      display: none;
    } 
  }