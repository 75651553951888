@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  scroll-behavior: smooth;
  /* max-width: 100vw; */
  /* font-size: 1.4rem;line-height: 1.6rem; */
}
html{
  font-size: 62.5%;
}
body{
  /* font-size: 1.4em; */
  background-color: #000;
  height: 100%;
}
:root{
  --primary_color:#d63447;
}
::selection{
  color: white;
  background-color: var(--primary_color);
}
.flex{
  display: flex;
  justify-content: center;
  align-items: center;
}
.fade-in {
  opacity: 0;
  -webkit-transition: opacity 250ms ease-in;
  transition: opacity 250ms ease-in; 
}

.appear {
  opacity: 1; 
}
.siteContainer{
  margin-bottom: 400px;
    position: relative;
    width: 100%;
    z-index: 1;
    background: #000;
}
#root{
  overflow-x: hidden;
}


div.code-toolbar>.toolbar{
  right: 38vmin !important;
}

@media only screen and (max-width:768px){
  div.code-toolbar>.toolbar{
    right: 1vmin !important;
  }
}